import { Component, OnInit } from '@angular/core';
import { CommandesService } from '../services/commandes/commandes.service';
import { baseURLAPISocket } from '../../environments/environment';
import io from 'socket.io-client';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css']
})
export class CommandesComponent implements OnInit {

  constructor(private cmdService: CommandesService) { }
  socket = io.connect(baseURLAPISocket, { transports: ['polling'] })
  commandes = [];

  ngOnInit() {
    this.getCommandes();
    this.socket.on('get-commandes', (data) => {
      console.log("data party front");
      console.log(data);
      this.commandes = data
    });
  }

  getCommandes() {
    this.cmdService.getCommandes().then(
      (res: []) => {
        console.log(res)
        this.commandes = res
      }
    )
  }

  updateCommande(cmd) {
    console.log("cmd");
    console.log(cmd);
    cmd.status = "in payment"
    this.cmdService.updateCommande(cmd).then(
      (res: []) => {
        console.log(res);
      }
    )
  }

  filterCommandesOfStatus() {
    return this.commandes.filter(x => x.status == "prepared");
  }

}
