import { Component, OnInit } from '@angular/core';
import { IngredientService } from '../services/ingredients/ingredient.service';

@Component({
  selector: 'app-bases',
  templateUrl: './bases.component.html',
  styleUrls: ['./bases.component.css']
})
export class BasesComponent implements OnInit {

  bases = [];

  constructor(private ingService: IngredientService) { }

  ngOnInit(): void {
    this.getBases()
  }

  getBases() {
    this.ingService.getBases().then(
      (res: []) => {
        console.log(res)
        this.bases = res
      }
    )
  }

  
  makeDispo(idBase) {
    this.ingService.disponibleBase(idBase).then(
      (res: []) => {
        this.bases = res
      }
    )
  }

  makeIndispo(idBase) {
    this.ingService.indisponibleBase(idBase).then(
      (res: []) => {
        this.bases = res
      }
    )
  }

}
