<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-danger">
                <div class="row">
                    <div class="col-md-9">
                        <h3 class="card-title ">Commandes</h3>
                        <p class="card-category">La list des commandes</p>
                    </div>
                    <div class="col-md-3">
                        <a class="nav-link" [routerLink]="['/ingredients']">
                            <div class="row">
                                <img src="./assets/img/ingredients.png" class="icon-ingredients">
                                <h2 class="card-title ">Ingrédients</h2>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-xs-12 col-md-12" *ngFor="let cmd of filterCommandesOfStatus()">
                        <!-- First product box start here-->
                        <div class="prod-info-main prod-wrap clearfix">
                            <div class="row">
                                <div class="col-md-2 col-sm-12 col-xs-12">
                                    <div class="product-image" style="background-image: url({{cmd.basePlat.image}}); background-size: cover; background-repeat: no-repeat; background-position: center;">
                                        <!-- <img [src]="ing.photo" class="img-responsive rotprod"> -->
                                        
                                    </div>
                                    <h5 class="ing-name">
                                        {{cmd.basePlat.label}}
                                    </h5>
                                    <h5 class="ing-name">
                                        {{cmd.basePlat.dose}} dose(s)
                                    </h5>
                                </div>

                                <div class="col-md-2 col-sm-12 col-xs-12" *ngFor="let ing of cmd.ingredients">
                                    <div class="product-image" style="background-image: url({{ing.photo}}); background-size: cover; background-repeat: no-repeat; background-position: center;">
                                        <!-- <img [src]="ing.photo" class="img-responsive rotprod"> -->
                                        
                                    </div>
                                    <h5 class="ing-name">
                                        {{ing.name}}
                                    </h5>
                                    <h5 class="ing-name">
                                        {{ing.dose}} dose(s)
                                    </h5>
                                </div>
                                
                                <!-- <div class="col-md-7 col-sm-12 col-xs-12">
                                    <div class="product-deatil">
                                        <h5 class="name">
                                            <a href="#">
                                                Product Code/Name here
                                            </a>
                                            <a href="#">
                                                <span>Product Category</span>
                                            </a>

                                        </h5>
                                        <p class="price-container">
                                            <span>$199</span>
                                        </p>
                                        <span class="tag1"></span>
                                    </div>
                                    <div class="description">
                                        <p>A Short product description here </p>
                                    </div>
                                    <div class="product-info smart-form">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <a href="javascript:void(0);" class="btn btn-danger btn-cart"><span>Add
                                                        to
                                                        cart</span></a>
                                                <a href="javascript:void(0);" class="btn btn-info"><span>More
                                                        info</span></a>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="rating">Rating:
                                                    <label for="stars-rating-5"><i
                                                            class="fa fa-star text-danger"></i></label>
                                                    <label for="stars-rating-4"><i
                                                            class="fa fa-star text-danger"></i></label>
                                                    <label for="stars-rating-3"><i
                                                            class="fa fa-star text-danger"></i></label>
                                                    <label for="stars-rating-2"><i
                                                            class="fa fa-star text-warning"></i></label>
                                                    <label for="stars-rating-1"><i
                                                            class="fa fa-star text-warning"></i></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-2 col-sm-10 col-xs-10">
                                    <h5 style="margin-top: 15px;"><strong>Ref:</strong> {{cmd.ref_cmd}}</h5>
                                </div>
                                <div class="col-md-2 col-sm-10 col-xs-10">
                                    <h5 style="margin-top: 15px;"><strong>Email:</strong> {{cmd.user.email_user}}</h5>
                                    <h5 style="margin-top: 15px;"><strong>Numéro Tel:</strong> {{cmd.user.tel_user}}</h5>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10">
                                    <h5 style="margin-top: 15px;"><strong>Commentaire:</strong> {{cmd.comments}}</h5>
                                    
                                </div>
                                <div *ngIf="cmd.modeLivraison=='livraison'" class="col-md-2 col-sm-10 col-xs-10">
                                    <h5 style="margin-top: 15px;"><strong>Mode de livraison:</strong> {{cmd.modeLivraison}} à {{cmd.heureLivrason}}</h5>
                                </div>
                                <div *ngIf="cmd.modeLivraison=='reservation'" class="col-md-2 col-sm-10 col-xs-10">
                                    <h5 style="margin-top: 15px;"><strong>Mode de livraison:</strong> {{cmd.modeLivraison}}</h5>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-2 carte-img">
                                    <a (click)="updateCommande(cmd)" class="btn btn-danger btn-cart"><span>{{cmd.status}}</span></a>
                                </div>
                            </div>
                        </div>
                        <!-- end product -->
                    </div>
                   
                </div>
                
            </div>
        </div>


    </div>
</div>