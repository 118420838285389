// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var baseURLAPI = 'https://lvclub.marketbey.tn/api/v0/';

export const baseURLAPISocket = 'https://lvclub.marketbey.tn/';

export const environment = {
  production: false,
  apiAddIngredient: baseURLAPI + 'ingredients/add',
  apiGetIngredients: baseURLAPI + 'ingredients/list',
  apiDeleteIngredient: baseURLAPI + 'ingredients/delete',
  apiGetOneIngredient: baseURLAPI + 'ingredients/getone',
  apiUpdateIngredient: baseURLAPI + 'ingredients/update',
  apiMakeDispoIngredient: baseURLAPI + 'ingredients/disponible',
  apiMakeIndispoIngredient: baseURLAPI + 'ingredients/indisponible',

  apiGetCommandes: baseURLAPI + 'commandes/list',
  apiGetCommandesInPayment: baseURLAPI + 'commandes/list/in-payment',
  apiGetCommandesPreparing: baseURLAPI + 'commandes/list/preparing',
  apiUpdateCommande: baseURLAPI + 'commandes/update',
  
  apiGetBases: baseURLAPI + 'ingredients/list-base',
  apiMakeDispoBase: baseURLAPI + 'ingredients/dispo-base',
  apiMakeIndispoBase: baseURLAPI + 'ingredients/indispo-base',
  
  // apiAuthUsers: baseURLAPI + 'tracking/auth',
  // apiAllCmds: baseURLAPI + 'tracking/cmds',
  // apiStatus: baseURLAPI + 'tracking/liststatus',
  // apiOneCmdByID: baseURLAPI + 'tracking/cmdbyid',
  // apiUpdateCmdStatus: baseURLAPI + 'tracking/updatecmdstatus',
  // baseImagesURL: 'https://www.bprice.tn/marketbey/assets/produits/',
};