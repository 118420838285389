import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseURLAPISocket, environment } from '../../../environments/environment';
import io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class CommandesService {

  socket = io.connect(baseURLAPISocket, {transports: ['polling']})
  
  constructor(private http: HttpClient) { }

  async getCommandes() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetCommandes, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getCommandesInPayment() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetCommandesInPayment, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getCommandesPreparing() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetCommandesPreparing, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async updateCommande(cmd) {
    return await new Promise((slv) => {
      this.http.post(environment.apiUpdateCommande, cmd).subscribe(
        (data: any) => {
          this.socket.emit('add-commande', "prepared");
          // this.socket.emit('commande-in-payment', "in-payment");
          console.log("data")
          console.log(data)
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
}
