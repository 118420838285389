import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseURLAPISocket, environment } from '../../../environments/environment';
import io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {

  socket = io.connect(baseURLAPISocket, {transports: ['polling']})
  
  constructor(private http: HttpClient) { }

  async addIngredient(fileToUpload: File, ingredient) {
    console.log("upload")
    var formData: FormData = new FormData();
    formData.append('file', fileToUpload)
    formData.append("name", ingredient.name);
    formData.append("prix", ingredient.prix);
    formData.append("calorie", ingredient.calorie);
    formData.append("photo", ingredient.photo);
    formData.append("status", ingredient.status);
    formData.append("classe", ingredient.classe); 
    console.log("formData service adddddd")
    console.log(formData)
    return await new Promise((slv) => {
      this.http.post(environment.apiAddIngredient, formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(
        (data: any) => {
          console.log("envoi msgggggggggg : ");
          this.socket.emit('add-ingredient', 'Hello there from Angular.');
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async updateIng(idIngredient, fileToUpload: File, ingredient) {
    console.log("update upload")
    console.log("ingredient service update")
    console.log(ingredient)
    var formData: FormData = new FormData();
    formData.append('file', fileToUpload)
    formData.append("name", ingredient.name);
    formData.append("prix", ingredient.prix);
    formData.append("calorie", ingredient.calorie);
    formData.append("photo", ingredient.photo);
    formData.append("status", ingredient.status);
    formData.append("classe", ingredient.classe); 
    console.log("formData service update")
    console.log(formData)
    return await new Promise((slv) => {
      this.http.post(environment.apiUpdateIngredient+ "/" + idIngredient, formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(
        (data: any) => {
          console.log("data service update ing")
          console.log(data)
          this.socket.emit('add-ingredient', 'Hello there from Angular.');
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          console.log("ingredient error")
          console.log(error)
          slv(false);
        }
      );
    });
  }

  async getIngredients() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetIngredients, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async disponibleBase(idBase) {
    return await new Promise((slv) => {
      this.http.post(environment.apiMakeDispoBase+ "/" + idBase, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  async indisponibleBase(idBase) {
    return await new Promise((slv) => {
      this.http.post(environment.apiMakeIndispoBase+ "/" + idBase, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  async getBases() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetBases, {}).subscribe(
        (data: any) => {
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async disponible(idIngredient, classeIngredient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiMakeDispoIngredient+ "/" + idIngredient, {}).subscribe(
        (data: any) => {
          this.socket.emit('get-ingredient', classeIngredient);
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async indisponible(idIngredient, classeIngredient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiMakeIndispoIngredient+ "/" + idIngredient, {}).subscribe(
        (data: any) => {
          this.socket.emit('get-ingredient', classeIngredient);
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async deleteIng(idIngredient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDeleteIngredient+ "/" + idIngredient, {}).subscribe(
        (data: any) => {
          this.socket.emit('add-ingredient', 'Hello there from Angular.');
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getIngredient(idIngredient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetOneIngredient+ "/" + idIngredient, {}).subscribe(
        (data: any) => {
          this.socket.emit('add-ingredient', 'Hello there from Angular.');
          if (data && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
}
