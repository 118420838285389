import { Component, OnInit } from '@angular/core';
import { IngredientService } from '../services/ingredients/ingredient.service';
import { baseURLAPISocket } from '../../environments/environment';
import io from 'socket.io-client';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.css']
})
export class IngredientsComponent implements OnInit {

  ingredients = [];
  socket = io.connect(baseURLAPISocket, {transports: ['polling']})
  constructor(private ingService: IngredientService) { }
  
  ngOnInit() {
    this.getIngredients();
    this.socket.on('get-ingredients', (data) => {
      console.log("data party front");
      console.log(data);
      this.ingredients = data
    });
  }

  getIngredients() {
    this.ingService.getIngredients().then(
      (res: []) => {
        console.log(res)
        this.ingredients = res
      }
    )
  }

  makeDispo(idIngredient, classeIngredient) {
    this.ingService.disponible(idIngredient, classeIngredient).then(
      (res: []) => {
        this.ingredients = res
      }
    )
  }

  makeIndispo(idIngredient, classeIngredient) {
    this.ingService.indisponible(idIngredient, classeIngredient).then(
      (res: []) => {
        this.ingredients = res
      }
    )
  }

}
