<div class="main-content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header card-header-danger">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="card-title ">Bases</h3>
                        <p class="card-category">La list des bases</p>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3" *ngFor="let base of bases">
                        <div
                            [ngClass]="base.status=='indisponible' ? 'prod-info-main prod-wrap clearfix disable-prod-info' : 'prod-info-main prod-wrap clearfix'">
                            <div class="product-deatil">
                                <div class="product-image"
                                    style="background-image: url({{base.image}}); background-position: center; background-repeat: no-repeat; background-size: cover;">
                                    <!-- <img [src]="ingredient.photo" class="img-responsive rotprod"> -->
                                    <span class="tag3 special">
                                        {{ base.calorie }} calories
                                    </span>
                                </div>
                                <h5 class="name">
                                    <a>
                                        {{ base.label }}
                                    </a>
                                </h5>
                            </div>
                            <div class="product-info smart-form">
                                <div class="row">
                                    <div class="col-md-12 btn-dispo">
                                        <a href="javascript:void(0);"
                                            (click)="(base.status=='disponible') ? makeIndispo(base._id) : makeDispo(base._id)"
                                            class="btn btn-danger btn-cart">
                                            <span>{{ base.status }}</span>
                                        </a>
                                        <!-- <a href="javascript:void(0);" class="btn btn-info">
                                            <span>More info</span></a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end product -->
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>